@import '~@angular/cdk/overlay-prebuilt.css';
@import '~@angular/material/theming';
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$bancrea-primary: mat-palette($mat-indigo);
$bancrea-accent: mat-palette($mat-green);

// The warn palette is optional (defaults to red).
$bancrea-warn: mat-palette($mat-red);
$bancrea-green: '#A3CA00';

// Create the theme object (a Sass map containing all of the palettes).
$bancrea-theme: mat-light-theme($bancrea-primary, $bancrea-accent, $bancrea-warn);
@include mat-progress-bar-theme($bancrea-theme);
@include mat-datepicker-theme($bancrea-theme);
@include mat-select-theme($bancrea-theme);
@include mat-checkbox-theme($bancrea-theme);
@include mat-autocomplete-theme($bancrea-theme);

html, body { height: 100%; }
body { margin: 0; font-family: 'SenticoSans'; }


.mat-form-field{color: #C8C8C8; font-size:inherit;font-weight:400;line-height:1.125;letter-spacing:normal}
.mat-form-field-wrapper{padding-bottom:0}
.mat-form-field-prefix .mat-icon,.mat-form-field-suffix .mat-icon{font-size:150%;line-height:1.125}
.mat-form-field-prefix .mat-icon-button,.mat-form-field-suffix .mat-icon-button{height:1.5em;width:1.5em;left:-15px;}
.mat-form-field-prefix .mat-icon-button .mat-icon,.mat-form-field-suffix .mat-icon-button .mat-icon{height:1.125em;line-height:1.125;}
.mat-form-field-infix{padding:.35em 0;border-top:.84375em solid transparent}
.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label{color: #7F8589 !important; transform:translateY(-1.34375em) scale(1);width:133.3333333333%}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown)+.mat-form-field-label-wrapper .mat-form-field-label{color: #7F8589 !important; transform:translateY(-1.34374em) scale(1);width:133.3333433333%}
.mat-form-field-label-wrapper{top:-1.04375em;padding-top:1.04375em}
.mat-form-field-label{top:1.34375em}
.mat-form-field-underline{bottom:1.34375em}
.mat-form-field-subscript-wrapper{font-size:75%;margin-top:.6666666667em;top:calc(100% - 1.7916666667em)}
.mat-form-field-appearance-legacy .mat-form-field-wrapper{padding-bottom:0}
.mat-form-field-appearance-legacy .mat-form-field-infix{padding:0 0}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label{color: #7F8589 !important; transform:translateY(-1.08125em) scale(1) perspective(100px) translateZ(0.001px);-ms-transform:translateY(-1.08125em) scale(1);width:133.3333333333%}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill+.mat-form-field-label-wrapper .mat-form-field-label{color: #7F8589 !important; transform:translateY(-1.28125em) scale(1) perspective(100px) translateZ(0.00101px);-ms-transform:translateY(-1.28124em) scale(1);width:133.3333433333%}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown)+.mat-form-field-label-wrapper .mat-form-field-label{color: #7F8589 !important; transform:translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);-ms-transform:translateY(-1.28123em) scale(1);width:133.3333533333%}
.mat-form-field-appearance-legacy .mat-form-field-label{top:1.08125em}
.mat-form-field-appearance-legacy .mat-form-field-underline{bottom:1.25em}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper{margin-top:.5416666667em;top:calc(100% - 1.6666666667em)}
@media print{.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label{color: #7F8589; transform:translateY(-1.28122em) scale(1)}
             .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill+.mat-form-field-label-wrapper .mat-form-field-label{color: #7F8589; transform:translateY(-1.28121em) scale(1)}
             .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown)+.mat-form-field-label-wrapper .mat-form-field-label{color: #7F8589; transform:translateY(-1.2812em) scale(1)}}

/* fixes */
.cdk-overlay-container {
  position: fixed;
  z-index: 1050;
  background: transparent;
}

.cdk-overlay-backdrop {
  background: white;
  backdrop-filter: blur(8px);
}

.cdk-overlay-backdrop.mat-overlay-transparent-backdrop.mat-datepicker-0-backdrop.cdk-overlay-backdrop-showing{
  background: transparent !important;  
}

.error-message_propio {
  background:  #EB5757;
  margin-top: 0.5rem;
  padding: 0.5rem;
  font-size: 0.875rem;
  color: white;
  text-align: center;
}

.warnings-message_propio {
  background: gold;
  margin-top: 0.5rem;
  padding: 0.5rem;
  font-size: 0.875rem;
  color: black;
  text-align: center;
}

.successmsg-message_propio {
  background:#009926;
  margin-top: 0.5rem;
  padding: 0.5rem;
  font-size: 0.875rem;
  color: white;
  text-align: center;
}

.encabezado-message_propio {
  background-color:  #C8C8C8;
  margin-top: 0.5rem;
  font-size: 0.875rem;
  color: black;
  text-align: center;
}
    
.mat-radio-outer-circle{
    border-style:none !important;
}
.mat-checkbox-frame{
    border-style:none !important;
}


 .mat-select-value {
     color: #004993 !important;
      border-bottom: 2px solid #e5e5e5;
 }
 
 .mat-select-value:disabled {
     opacity: 0.6 !important;
 }
 
 .mat-select-value:enabled {
     opacity: 1 !important;
 }
 
.sizeContainer{
    &.mat-form-field-appearance-legacy .mat-form-field-infix{
        padding: 0em 0 0em 0;
        height: 2.313rem;
    }
}

.cdk-live-announcer-element{
    display:none;
}

.mat-dialog-container {
    min-height:200px !important;
    max-height:200px !important;
}
  
.mat-datepicker-close-button {
   display: none !important;
}

.mat-checkbox-inner-container
{
    height: 24px !important;
    width: 24px !important;
}
.mat-dialog-content{
 overflow: hidden !important;
}